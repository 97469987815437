import Vue from "vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const RESET_PASSWORD = "resetPassword";
export const SAVE_RESET_PASSWORD = "saveResetPassword";
export const VALIDAR_2FA = "validar2FA";
export const REENVIAR_2FA = "reenviar2FA";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_VALIDAR_2FA = "setValidar2FA";

const state = {
  errors: null,
  user: JSON.parse(localStorage.getItem("usuario")),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  checkSuperAdmin(state) {
    return state.user.role.name == "super-administrador";
  },
  checkAdminGeral(state) {
    return state.user.role.name == "administrador-geral";
  },
  isAdmin(state) {
    return (
      state.user.role.name == "super-administrador" &&
      isNaN(parseInt(state.user.conta_id))
    );
  },
  hasPermission: (state) => (permission) => {
    return state.user.permissoes.indexOf(permission) !== -1;
  },
  userRole(state) {
    return state.user.role;
  },
  recursoAtivoConta: (state) => (recurso) => {
    return state.user.recursos.indexOf(recurso) !== -1;
  },
  validar2FA(state) {
    return state.user.validar_2fa;
  },
  hasAccount(state) {
    return !isNaN(parseInt(state.user.conta_id));
  },
  isAccountAdmin(state) {
    const accountAdminRoles = ["administrador-geral", "administrador"];
    return accountAdminRoles.indexOf(state.user.role.name) !== -1;
  },
  contasParaTroca(state) {
    return state.user.contas_troca.sort((a, b) =>
      a.login.localeCompare(b.login)
    );
  }
};

const actions = {
  [LOGIN](context, credentials) {
    localStorage.removeItem("exibirDashboard");

    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, { data: data.data, verify: true });
          localStorage.setItem("usuario", JSON.stringify(data.data.usuario));
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [LOGOUT](context, serverLogout = false) {
    if (serverLogout) {
      return new Promise((resolve, reject) => {
        ApiService.get("logout")
          .then(({ data }) => {
            context.commit(PURGE_AUTH);
            resolve(data);
          })
          .catch((error) => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  async [VERIFY_AUTH](
    context,
    { exibirLoader = true, atualizarDadosUsuario = false }
  ) {
    let token = JwtService.getToken();

    if (token) {
      ApiService.setHeader();

      if (atualizarDadosUsuario) {
        await ApiService.get("verificar-login", "", exibirLoader)
          .then(({ data }) => {
            context.commit(SET_AUTH, { data: data.data, verify: false });
          })
          .catch((error) => {
            context.commit(SET_ERROR, error);
          });
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return ApiService.put("usuario/alterar-senha", payload);
  },
  [RESET_PASSWORD](context, email) {
    return ApiService.post("usuario/gerar-token-recuperar-senha", email);
  },
  [SAVE_RESET_PASSWORD](context, params) {
    return ApiService.post("usuario/recuperar-senha", params);
  },
  [VALIDAR_2FA](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("validar-2fa", payload)
        .then(({ data }) => {
          context.commit(SET_VALIDAR_2FA);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },

  [REENVIAR_2FA](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("reenviar-2fa", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, { data, verify }) {
    state.isAuthenticated = true;
    state.user = data.usuario;
    state.errors = {};

    if (verify) {
      JwtService.saveToken(data.token, data.expiracao);
    }

    localStorage.setItem("usuario", JSON.stringify(state.user));
    this.dispatch(UPDATE_PERSONAL_INFO, state.user);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};

    localStorage.removeItem("usuario");
    JwtService.destroyToken();
  },
  [SET_VALIDAR_2FA](state) {
    state.user.validar_2fa = false;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
