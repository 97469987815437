export const SET_VALIDATOR_ERROR = "setValidatorError";

export default {
  state: {
    errors: null
  },
  getters: {
    validatorErrors(state) {
      return state.errors;
    }
  },
  actions: {
    [SET_VALIDATOR_ERROR](state, payload) {
      state.commit(SET_VALIDATOR_ERROR, payload);
    }
  },
  mutations: {
    [SET_VALIDATOR_ERROR](state, payload) {
      state.errors = payload;
    }
  }
};
