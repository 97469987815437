<template>
  <div class="d-inline-flex" :class="{ 'align-middle mr-2': adicionarClasses }">
    <template v-if="tipoBotao === 'icone'">
      <b-button
        :variant="variant"
        size="sm"
        class="btn-icon"
        v-b-tooltip.noninteractive
        title="Ler QRCode"
        @click="exibirQRCode"
      >
        <i class="fas fa-qrcode"></i>
      </b-button>
    </template>

    <template v-else-if="tipoBotao === 'botao'">
      <b-button :variant="variant" size="sm" @click="exibirQRCode">
        {{ textoBotao }}
      </b-button>
    </template>

    <template v-else>
      <a @click="exibirQRCode" href="javascript:void(0)">{{ textoBotao }}</a>
    </template>

    <b-modal
      ref="mdlLerQRCode"
      hide-footer
      hide-header
      @hidden="posValidacaoChat"
    >
      <div class="modal-content">
        <div class="modal-body">
          <h3 style="text-align: center">
            Após ler o QRCode, clique no botão Ativar.
          </h3>
          <br />
          <img
            :src="qrCode"
            class="img-responsive"
            style="display: block; margin: 0 auto"
          />
        </div>
        <div
          class="modal-footer"
          style="text-align: center; padding: 10px 40px"
        >
          <button
            type="button"
            style="font-size: 1.3em"
            class="btn btn-lg btn-success btn-block"
            data-dismiss="modal"
            @click="esconderQRCode"
          >
            Ativar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  props: {
    ativarChat: {
      type: Boolean,
      default: false
    },
    conta: {
      type: Object,
      default: null
    },
    variant: {
      type: String,
      default: "light-primary"
    },
    tipoBotao: {
      type: String,
      default: "icone"
    },
    textoBotao: {
      type: String,
      default: "Clique aqui para conectar!"
    },
    gestorCanais: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      qrCode: "",
      atualizarAoConectar: true
    };
  },

  mounted() {
    if (this.gestorCanais) {
      this.$bus.$on(
        "gestor:atualizar-contas",
        function (conta) {
          if (
            this.conta.instancia === conta.instancia &&
            this.conta.id !== conta.id
          ) {
            this.posValidacaoChat(false, true);
          }
        }.bind(this)
      );
    }
  },

  methods: {
    posValidacaoChat(emitirEventoConectado = true, exibirLoader = true) {
      clearTimeout(this.timeout);

      ApiService.post(
        "/dashboard/status",
        {
          idConta: this.conta ? this.conta.id : null
        },
        null,
        exibirLoader
      ).then((response) => {
        if (this.conta) {
          const conta = response.data.data;

          if (!conta.validacaoChat) {
            this.conta.validacaoChat = false;
            this.conta.picture = conta.picture;
            this.conta.pushname = conta.pushname;
            this.conta.phone = conta.phone;

            this.atualizarAoConectar = false;
            if (emitirEventoConectado && this.gestorCanais) {
              this.$bus.$emit("gestor:atualizar-contas", this.conta);
            }
          }
        } else {
          window.location.reload();
        }
      });
    },

    esconderQRCode() {
      this.$refs["mdlLerQRCode"].hide();
    },

    exibirQRCode() {
      ApiService.post("/dashboard/conectar", {
        idConta: this.conta ? this.conta.id : null
      }).then((response) => {
        const conta = response.data.data;

        if (conta.validacaoChat) {
          this.qrCode = conta.qrCode;
          this.$refs["mdlLerQRCode"].show();
          clearTimeout(this.timeout);
          this.atualizarQRCode();
        } else {
          Swal.fire({
            title: "MENSAGEM",
            text: "Está conta já está conectada!",
            icon: "success",
            heightAuto: false
          });

          this.conta.validacaoChat = false;
          this.conta.picture = conta.picture;
          this.conta.pushname = conta.pushname;
          this.conta.phone = conta.phone;
        }
      });
    },

    atualizarQRCode() {
      this.timeout = setTimeout(() => {
        ApiService.post(
          "/dashboard/conectar",
          {
            idConta: this.conta ? this.conta.id : null
          },
          null,
          false
        ).then((response) => {
          const conta = response.data.data;

          if (conta.validacaoChat) {
            this.qrCode = conta.qrCode;
            this.atualizarQRCode();
          } else {
            this.esconderQRCode();
          }
        });
      }, 10000);
    }
  },

  computed: {
    adicionarClasses() {
      return this.tipoBotao === "icone";
    }
  }
};
</script>
