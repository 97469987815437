<template>
  <div class="emoji-wrapper">
    <b-form-textarea
      v-model="inputValue"
      :name="name"
      rows="8"
      max-rows="8"
      no-auto-shrink
    ></b-form-textarea>
    <emoji-picker @emoji="append" :search="search">
      <div
        class="emoji-invoker"
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        @click.stop="clickEvent"
      >
        <svg
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
          />
        </svg>
      </div>
      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
        <div class="emoji-picker" :style="{ top: '30px', right: '20px' }">
          <div class="emoji-picker__search">
            <input type="text" class="form-control" v-model="search" />
          </div>
          <div>
            <div class="emojis">
              <template v-for="(emojiGroup, category) in emojis">
                <template v-if="category != 'Frequently used'">
                  <span
                    v-for="(emoji, emojiName) in emojiGroup"
                    :key="emojiName"
                    @click="insert(emoji)"
                    :title="emojiName"
                    >{{ emoji }}</span
                  >
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </emoji-picker>
  </div>
</template>

<script>
import EmojiPicker from "vue-emoji-picker";

export default {
  props: ["value", "name"],
  data() {
    return {
      inputValue: this.value,
      search: ""
    };
  },
  components: {
    EmojiPicker
  },
  methods: {
    append(emoji) {
      this.inputValue += emoji;
    }
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
    inputValue() {
      this.$emit("updated", this.inputValue);
    }
  }
};
</script>

<style>
.emoji-wrapper {
  position: relative;
}
.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 1.6rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}
.emoji-picker {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  width: 20rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1.5rem;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
</style>
