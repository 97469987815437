import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../src/view/pages/painel/automacao/api.links";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

// -----------------------------------------
import VCalendar from "v-calendar";
import VModal from "vue-js-modal";
import vuescroll from "vue-scroll";
import VAnimateCss from "v-animate-css";
import "vue2-timepicker/dist/VueTimepicker.css";
import "animate.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";

import higchartsMore from "highcharts/highcharts-more";
import VueMousetrapPlugin from "vue-mousetrap/vue2";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faArrowsSpin } from "@fortawesome/free-solid-svg-icons";

import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(faUserSecret, faArrowsSpin);

Vue.use(VueMousetrapPlugin);

higchartsMore(Highcharts);

Vue.use(HighchartsVue);
Vue.use(ConfirmationService);
Vue.use(ToastService);
Vue.use(VAnimateCss);
Vue.use(VModal);
Vue.use(vuescroll);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCompositionAPI);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
});

Vue.use(VCalendar, {
  componentPrefix: "vc"
});
// -----------------------------------------

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/utils";

import TextareaEmoji from "@/view/components/TextareaEmoji";
Vue.component("textarea-emoji", TextareaEmoji);

import GeradorSenha from "@/view/components/GeradorSenha/GeradorSenha";
Vue.component("gerador-senha", GeradorSenha);

import ModalLerQRCode from "@/view/components/ModalLerQRCode";
Vue.component("modal-qrCode", ModalLerQRCode);

import "@/core/plugins/primevue";

import VueNativeNotification from "vue-native-notification";
Vue.use(VueNativeNotification);

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: "G-8ZY6ZN1VSR" }
  },
  router
);
// API service init
ApiService.init();

localStorage.removeItem("exibirDashboard");

router.beforeEach((to, from, next) => {
  const exibirLoader = to.name !== "dashboard";
  const atualizarDadosUsuario = !from.name;

  if (to.name == "oauth-redirect") {
    next();
  } else {
    // Ensure we checked auth before each page load.
    Promise.all([
      store.dispatch(VERIFY_AUTH, {
        exibirLoader: exibirLoader,
        atualizarDadosUsuario: atualizarDadosUsuario
      })
    ]).then(() => {
      const isAuthenticated = store.getters.isAuthenticated;
      const checkPermission = to.meta.checkPermission;
      const validar2FA = store.getters.validar2FA;

      if (!isAuthenticated) {
        if (!checkPermission) {
          next();
        } else if (to.name !== "login") {
          next({ name: "login" });
        }
      } else {
        const defaultRoute = store.getters.currentUser.menuPrincipal;

        let hasPermission = true;
        if (checkPermission) {
          hasPermission = store.getters.hasPermission(to.meta.permission);
        }

        if (validar2FA && to.name !== "validar-2fa") {
          next({ name: "validar-2fa" });
        } else if (checkPermission && !hasPermission) {
          router.push(defaultRoute);
        } else {
          next();
        }
      }
    });

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
});

async function getFF(feature_flag_id) {
  try {
    const feature_flag = await ApiService.get(
      API_LINKS.feature_flags + "/" + feature_flag_id
    );
    return feature_flag.data.feature;
  } catch (error) {
    console.error("error", error);
    return false;
  }
}
Vue.prototype.$getFeatureFlag = getFF;

Vue.prototype.$bus = new Vue({});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

window.chatwootRemoveWidgets = function () {
  delete window.chatwootSDK;
  delete window.$chatwoot;

  const BASE_URL = "https://chat.wellon.digital/packs/js/sdk.js";
  const allsuspects = document.getElementsByTagName("script");
  const wootWidgetHolder = document.querySelector(".woot-widget-holder");
  const wootBubbleHolder = document.querySelector(".woot--bubble-holder");
  if (wootWidgetHolder) wootWidgetHolder.remove();
  if (wootBubbleHolder) wootBubbleHolder.remove();
  for (let i = allsuspects.length; i >= 0; i--) {
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute("src") != null &&
      allsuspects[i].getAttribute("src").indexOf(BASE_URL) != -1
    ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};

window.chatwootLaunchForChatbot = function () {
  window.chatwootSettings = {
    position: "left",
    type: "standard",
    hideMessageBubble: true
  };
  (function (d, t) {
    const BASE_URL = "https://chat.wellon.digital";
    const g = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: "vJobnyKPp7xowksykRtWqd4r",
        baseUrl: BASE_URL
      });
    };
  })(document, "script");
};

window.chatwootLaunchForSupport = function () {
  window.chatwootSettings = {
    position: "right",
    type: "expanded_bubble",
    launcherTitle: "Falar com o nosso time"
  };

  (function (d, t) {
    const BASE_URL = "https://chat.wellon.digital";
    const g = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: "v4WQiS4sbJ8D1prq6w1wqvNT",
        baseUrl: BASE_URL
      });
    };
  })(document, "script");
};
