export const SHOW_OVERLAY = "showOverlay";
export const COUNTER_INCREMENT = "counterIncrement";
export const COUNTER_DECREMENT = "counterDecrement";

export default {
  state: {
    overlay: false,
    counter: 0
  },
  getters: {
    overlay(state) {
      return state.overlay;
    }
  },
  actions: {
    [SHOW_OVERLAY](state, payload) {
      if (payload) {
        state.commit(COUNTER_INCREMENT);
      } else {
        state.commit(COUNTER_DECREMENT);
      }

      state.commit(SHOW_OVERLAY, payload);
    }
  },
  mutations: {
    [SHOW_OVERLAY](state, payload) {
      if (!payload && state.counter > 0) {
        return false;
      }
      state.overlay = payload;
    },
    [COUNTER_INCREMENT](state) {
      state.counter++;
    },
    [COUNTER_DECREMENT](state) {
      state.counter--;
    }
  }
};
