import JwtService from "@/core/services/jwt.service";
import store from "@/core/services/store";
import { LOGOUT } from "@/core/services/store/auth.module";
import { SHOW_OVERLAY } from "@/core/services/store/overlay.module";
import { SET_VALIDATOR_ERROR } from "@/core/services/store/validator.module";
import router from "@/router";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import VueAxios from "vue-axios";
import moment from "moment";
import getBrowserFingerprint from "get-browser-fingerprint";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_COMUNICANTE_API_PAINEL;

    Vue.axios.interceptors.request.use(
      (config) => {
        store.dispatch(SET_VALIDATOR_ERROR, null);
        config.headers["Browser-Fingerprint"] = getBrowserFingerprint();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      (response) => {
        if (response.config.headers.showOverlay) {
          store.dispatch(SHOW_OVERLAY, false);
        }

        const refreshedToken =
          response.headers.authorization?.split("Bearer ")[1];

        if (refreshedToken) {
          const data = moment().format("YYYY-MM-DD HH:mm:ss");
          JwtService.saveToken(refreshedToken, data);
          this.setHeader();
        }

        return response;
      },
      (error) => {
        if (error.response) {
          const statusCode = error.response.status;
          const data = error.response.data;
          const errorMessage =
            data.message || data.mensagem || error.response.statusText;
          const permissionError = data.permissionError;

          switch (statusCode) {
            case 400:
            case 403:
            case 404:
            case 500:
              if (permissionError) {
                router.push("/").then(() => {
                  Swal.fire({
                    title: "ERRO",
                    text: errorMessage,
                    icon: "error",
                    heightAuto: false
                  });
                });
              } else {
                Swal.fire({
                  title: "ERRO",
                  text: errorMessage,
                  icon: "error",
                  heightAuto: false
                });
              }

              break;
            case 401:
              store.dispatch(LOGOUT, false).then(() => {
                router.push({ name: "login" }).then(() => {
                  const mensagem = errorMessage
                    ? errorMessage
                    : "Sua conta foi deslogada, informe suas credenciais novamente!";

                  if (errorMessage) {
                    Swal.fire({
                      title: "ERRO",
                      text: mensagem,
                      icon: "error",
                      heightAuto: false
                    });
                  }
                });
              });

              break;
            case 422:
              store.dispatch(SET_VALIDATOR_ERROR, data.data);

              break;
            default:
              break;
          }
        } else if (error.request) {
          Swal.fire({
            title: "ERRO",
            text: "Houve um erro com a sua solicitação ou com a conexão com o servidor.",
            icon: "error",
            heightAuto: false
          });
        }

        if (error.config.headers.showOverlay) {
          store.dispatch(SHOW_OVERLAY, false);
        }

        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", showOverlay = true, config = null) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    if (config) {
      if (config.headers) {
        config.headers.showOverlay = showOverlay;
      } else {
        config.headers = { showOverlay: showOverlay };
      }
    } else {
      config = { headers: { showOverlay: showOverlay } };
    }

    const url = slug ? `${resource}/${slug}` : `${resource}`;

    return Vue.axios.get(url, config);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params, config = null, showOverlay = true) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    if (config) {
      if (config.headers) {
        config.headers.showOverlay = showOverlay;
      } else {
        config.headers = { showOverlay: showOverlay };
      }
    } else {
      config = { headers: { showOverlay: showOverlay } };
    }

    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  patch(resource, params, config = null, showOverlay = true) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    if (config) {
      if (config.headers) {
        config.headers.showOverlay = showOverlay;
      } else {
        config.headers = { showOverlay: showOverlay };
      }
    } else {
      config = { headers: { showOverlay: showOverlay } };
    }

    return Vue.axios.patch(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, showOverlay = true) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    return Vue.axios.put(`${resource}/${slug}`, params, {
      headers: { showOverlay: showOverlay }
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, showOverlay = true) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    return Vue.axios.put(`${resource}`, params, {
      headers: { showOverlay: showOverlay }
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, params, showOverlay = true) {
    if (showOverlay) {
      store.dispatch(SHOW_OVERLAY, true);
    }

    return Vue.axios.delete(resource, {
      data: params,
      headers: { showOverlay: showOverlay }
    });
  }
};

export default ApiService;
