"use strict";

function currentlyChatwootFull() {
  const woot_bubble = document.querySelector(".woot-widget-bubble");
  if (woot_bubble && woot_bubble.classList.contains("toggled-woot")) {
    return false;
  }
  return true;
}
function hideChatwoot() {
  if (window.$chatwoot) {
    try {
      const exapnded_text = document.getElementById(
        "woot-widget--expanded__text"
      );
      if (exapnded_text) exapnded_text.style.display = "none";
      const widget_bubble_icon = document.getElementById(
        "woot-widget-bubble-icon"
      );
      if (widget_bubble_icon) widget_bubble_icon.style.margin = "14px";
      const widget_bubble = document.querySelector(".woot-widget-bubble");
      if (widget_bubble) widget_bubble.style.height = "unset";
      const woot_bubble = document.querySelector(".woot-widget-bubble");
      if (woot_bubble) woot_bubble.classList.add("toggled-woot");
    } catch (error) {
      console.log(error);
    }
  }
}
function showChatwoot() {
  if (window.$chatwoot) {
    try {
      const exapnded_text = document.getElementById(
        "woot-widget--expanded__text"
      );
      if (exapnded_text) exapnded_text.style.display = "flex";
      const widget_bubble_icon = document.getElementById(
        "woot-widget-bubble-icon"
      );
      if (widget_bubble_icon)
        widget_bubble_icon.style.margin = "14px 8px 14px 16px";
      const widget_bubble = document.querySelector(".woot-widget-bubble");
      if (widget_bubble) widget_bubble.style.height = "48px";
      const woot_bubble = document.querySelector(".woot-widget-bubble");
      if (woot_bubble) woot_bubble.classList.remove("toggled-woot");
    } catch (error) {
      console.log(error);
    }
  }
}
var KTLayoutAsideToggle = (function () {
  // Private properties
  var _body;
  var _element;
  var _toggleObject;

  // Initialize
  var _init = function () {
    _toggleObject = new KTToggle(_element, _body, {
      targetState: "aside-minimize",
      toggleState: "active"
    });

    _toggleObject.on("toggle", function (toggle) {
      // Update sticky card
      if (typeof KTLayoutStickyCard !== "undefined") {
        KTLayoutStickyCard.update();
      }

      // Pause header menu dropdowns
      if (typeof KTLayoutHeaderMenu !== "undefined") {
        KTLayoutHeaderMenu.pauseDropdownHover(800);
      }

      // Pause aside menu dropdowns
      if (typeof KTLayoutAsideMenu !== "undefined") {
        KTLayoutAsideMenu.pauseDropdownHover(800);
      }

      // Remember state in cookie
      KTCookie.setCookie("kt_aside_toggle_state", toggle.getState());
      // to set default minimized left aside use this cookie value in your
      // server side code and add "kt-primary--minimize aside-minimize" classes to
      // the body tag in order to initialize the minimized left aside mode during page loading.

      // if (currentlyChatwootFull()) {
      //   hideChatwoot();
      // } else {
      //   showChatwoot();
      // }
    });

    _toggleObject.on("beforeToggle", function (toggle) {
      if (
        KTUtil.hasClass(_body, "aside-minimize") === false &&
        KTUtil.hasClass(_body, "aside-minimize-hover")
      ) {
        KTUtil.removeClass(_body, "aside-minimize-hover");
      }
    });
  };

  // Public methods
  return {
    init: function (id) {
      _element = KTUtil.getById(id);
      _body = KTUtil.getBody();

      if (!_element) {
        return;
      }

      // Initialize
      _init();
    },

    getElement: function () {
      return _element;
    },

    getToggle: function () {
      return _toggleObject;
    },

    onToggle: function (handler) {
      if (typeof _toggleObject.element !== "undefined") {
        _toggleObject.on("toggle", handler);
      }
    }
  };
})();

export default KTLayoutAsideToggle;
