import Vue from "vue";
import moment from "moment-timezone";

const timezone = "America/Sao_Paulo";

const Utils = {
  install(Vue) {
    Vue.prototype.formatarInteiro = (numero) => {
      return new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        maximumFractionDigits: 0
      }).format(numero);
    };

    Vue.prototype.obterDatas = () => {
      return {
        hoje: moment().tz(timezone),
        ontem: moment().subtract(1, "days").tz(timezone),
        ultimos3Dias: moment().subtract(2, "days").tz(timezone),
        ultimaSemana: moment().subtract(6, "days").tz(timezone),
        inicioMesAtual: moment().startOf("month").tz(timezone),
        fimMesAtual: moment().endOf("day").tz(timezone),
        inicioMesAnterior: moment()
          .subtract(1, "months")
          .startOf("month")
          .tz(timezone),
        fimMesAnterior: moment()
          .subtract(1, "months")
          .endOf("month")
          .tz(timezone),
        ultimos30Dias: moment().subtract(29, "days").tz(timezone),
        ultimos3Meses: moment().subtract(2, "months").tz(timezone),
        ultimos6Meses: moment().subtract(5, "months").tz(timezone),
        ultimos12Meses: moment().subtract(11, "months").tz(timezone)
      };
    };

    Vue.prototype.obterPeriodo = (dataInicial, dataFinal) => {
      moment.locale("pt-br");

      const datas = Vue.prototype.obterDatas();

      dataInicial = moment(dataInicial).tz(timezone);
      dataFinal = moment(dataFinal).tz(timezone);
      const data_inicial_ajustada = dataInicial.clone().subtract(3, "hours");
      const data_final_ajustada = dataFinal.clone().subtract(3, "hours");
      let periodo = "";
      let textoDataInicial = "";
      let textoDataFinal = "";

      if (
        dataInicial.isSame(dataFinal, "day") &&
        dataInicial.isSame(datas.hoje, "day")
      ) {
        textoDataInicial = "Hoje: ";
        textoDataFinal = dataInicial.format("DD/MM/YYYY");

        periodo = {
          value: "HJ",
          text: "Hoje"
        };
      } else if (
        dataInicial.isSame(dataFinal, "day") &&
        dataInicial.isSame(datas.ontem, "day")
      ) {
        textoDataInicial = "Ontem: ";
        textoDataFinal = dataInicial.format("DD/MM/YYYY");

        periodo = {
          value: "ON",
          text: "Ontem"
        };
      } else {
        textoDataInicial = "";
        textoDataFinal =
          dataInicial.format("DD/MM/YYYY") +
          " - " +
          dataFinal.format("DD/MM/YYYY");

        if (
          dataInicial.isSame(datas.ultimos3Dias, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "U3D",
            text: "Últimos 3 dias"
          };
        } else if (
          dataInicial.isSame(datas.ultimaSemana, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "US",
            text: "Última semana"
          };
        } else if (
          dataInicial.isSame(datas.inicioMesAtual, "day") &&
          dataFinal.isSame(datas.fimMesAtual, "day")
        ) {
          periodo = {
            value: "MA",
            text: "Mês atual"
          };
        } else if (
          dataInicial.isSame(datas.inicioMesAnterior, "day") &&
          dataFinal.isSame(datas.fimMesAnterior, "day")
        ) {
          periodo = {
            value: "MAN",
            text: "Mês anterior"
          };
        } else if (
          dataInicial.isSame(datas.ultimos30Dias, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "30D",
            text: "Últimos 30 dias"
          };
        } else if (
          dataInicial.isSame(datas.ultimos3Meses, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "3M",
            text: "Últimos 3 meses"
          };
        } else if (
          dataInicial.isSame(datas.ultimos6Meses, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "6M",
            text: "Últimos 6 meses"
          };
        } else if (
          dataInicial.isSame(datas.ultimos12Meses, "day") &&
          dataFinal.isSame(datas.hoje, "day")
        ) {
          periodo = {
            value: "12M",
            text: "Últimos 12 meses"
          };
        } else {
          periodo = {
            value: "personalizado",
            text: "Personalizado"
          };
        }
      }

      return {
        periodo,
        dataInicial: data_inicial_ajustada,
        dataFinal: data_final_ajustada,
        textoDataInicial,
        textoDataFinal
      };
    };

    // Vue.prototype.maskPhone = $element => {
    //   var PhoneMaskBehavior = function (val) {
    //     return val.replace(/\D/g, "").length === 11
    //       ? "(00) 00000-0000"
    //       : "(00) 0000-00009";
    //   },
    //     maskOptions = {
    //       onKeyPress: function (val, e, field, options) {
    //         field.mask(PhoneMaskBehavior.apply({}, arguments), options);
    //       }
    //     };

    //   $element.each(function () {
    //     this.mask(PhoneMaskBehavior, maskOptions);
    //   });
    // };
  }
};

Vue.use(Utils);
