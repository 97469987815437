<template>
  <div id="geradorSenha">
    <a
      href="#"
      class="btn btn-light btn-text-dark btn-sm"
      @click.stop.prevent="exibirSenha"
      ><i class="fa fa-key"></i> Gerador de senha</a
    >
  </div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import GeradorSenhaComponente from "./GeradorSenhaComponente";

export default {
  name: "GeradorSenha",
  props: {
    comprimento: {
      type: Number,
      default: 20
    },
    simbolos: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      senha: ""
    };
  },
  methods: {
    exibirSenha() {
      Swal.fire({
        title: `Senha Gerada:`,
        html: `<div id="divSwalGeradorSenha" class="w-100"></div>`,
        showConfirmButton: false,
        onBeforeOpen: () => {
          let ComponentClass = Vue.extend(GeradorSenhaComponente);
          let instance = new ComponentClass({
            propsData: {
              comprimento: this.comprimento,
              simbolos: this.simbolos
            }
          });

          instance.$mount();
          instance.$on("novaSenha", (senha) => (this.senha = senha));

          document
            .getElementById("divSwalGeradorSenha")
            .appendChild(instance.$el);
        }
      }).then((result) => {
        if (result.value) {
          this.$emit("usar-senha", this.senha);
        }
      });
    }
  }
};
</script>
