<template>
  <div>
    <h2 class="font-weight-boldest mb-4" id="geradorSenha">{{ this.senha }}</h2>
    <div class="alert alert-warning mb-0 text-left font-size-sm" role="alert">
      <i class="fas fa-exclamation-triangle text-white mr-2"></i>
      Copie a senha e armazene em um local seguro! <br />
    </div>
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-secondary btn-block"
        @click="fechar()"
      >
        Fechar
      </button>

      <button
        type="button"
        class="btn btn-sm btn-success btn-block"
        @click="copiar()"
        id="btnCopiar"
      >
        Copiar

        <b-tooltip ref="tooltipCopiar" target="btnCopiar" disabled>
          Copiado!
        </b-tooltip>
      </button>

      <button
        type="button"
        class="btn btn-sm btn-primary btn-block"
        @click="gerarSenha()"
      >
        Nova Senha
      </button>
    </div>
    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-sm btn-info btn-block"
        @click="usarSenha()"
      >
        Usar Senha
      </button>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import generator from "generate-password";

export default {
  name: "GeradorSenhaComponente",
  props: {
    comprimento: {
      type: Number,
      default: 20
    },
    simbolos: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      senha: ""
    };
  },
  mounted() {
    this.gerarSenha();
  },
  methods: {
    gerarSenha() {
      this.senha = generator.generate({
        length: this.comprimento,
        numbers: true,
        symbols: this.simbolos ? "!@#$%&*" : false,
        strict: true
      });
    },

    copiar() {
      navigator.clipboard.writeText(this.senha).then(() => {
        this.$refs.tooltipCopiar.$emit("open");
        setTimeout(() => {
          this.$refs.tooltipCopiar.$emit("close");
        }, 1000);
      });
    },

    fechar() {
      Swal.close();
    },

    usarSenha() {
      Swal.clickConfirm();
    }
  },
  watch: {
    senha() {
      this.$emit("novaSenha", this.senha);
    }
  }
};
</script>
