import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Install BootstrapVue
Vue.use(BootstrapVue, {
  BFormDatepicker: {
    labelHelp: "Use as setas para navegar entre as datas",
    labelNoDateSelected: "Selecione uma data",
    dateFormatOptions: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }
  }
});
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
